import axios from "axios";
import store from "../store";

import VueCookies from "vue-cookies";
import { useFavicon } from "@vueuse/core";
import moment from "moment";
import { action as ActionList } from "@/assets/json/logger";
import BrandKey from "@/assets/json/logbrand";
const icon = useFavicon();
axios.interceptors.request.use(function (config) {
  return config;
});

axios.interceptors.response.use(function (response) {
  return Promise.resolve(response.data);
});

let baseURL = "";
let location = window.location.hostname;
let brand = location.split("-")[0];
var subdomain = location.split(".");
subdomain.shift();
subdomain = subdomain.join(".");

var mode = "prod";
if (brand == "hourglass") subdomain = "dosetech.co";
if (
  brand == "linecrm" ||
  brand == "d" ||
  process.env.NODE_ENV == "development"
) {
  baseURL = "https://api-hourglass-mobile-dev.dosetech.co";

  mode = "dev";
} else if (brand == "dcrm")
  baseURL = "https://api-dcrm-demo-mobile.dosetech.co";
else {
  if (brand == "clarins") {
    baseURL = "https://clubbeaute-mobile-api.dosetech.co";
  } else {
    baseURL = `${window.location.protocol}//api-${brand}-mobile-${mode}.${subdomain}`;
  }
}

const instance = axios.create({
  withCredentials: true,
  baseURL: baseURL,
  responseType: "json"
});

instance.interceptors.request.use(function (config) {
  config.metadata = { startTime: new Date() };
  if (VueCookies.isKey("token")) {
    config.headers.Authorization = `Bearer ${VueCookies.get("token")}`;
  }
  let lang = null;
  if (VueCookies.isKey("lang")) {
    lang = VueCookies.get("lang").lang;
  }

  config.headers.common["API-KEY"] = process.env.VUE_APP_API_KEY;
  config.headers.common["LANGUAGE-ID"] = lang || 1;
  config.headers.common["Access-Control-Allow-Origin"] = "*";
  return config;
});
// Add API response
instance.interceptors.response.use(
  function (response) {
    if (response.data.result == 0) logger(response);
    return Promise.resolve(response.data);
  },
  function (error) {
    logger(error.response);
    return Promise.reject(error.response);
  }
);
const logger = response => {
  try {
    let config = response.config;
    config.metadata.endTime = new Date();

    let start = moment(config.metadata.startTime);
    let end = moment(config.metadata.endTime);
    let duration = moment
      .utc(moment(end).diff(moment(start)))
      .format("HH:mm:ss.SSS");

    let action = ActionList.find(
      el =>
        config.url.includes(el.url) &&
        (el.path == "" || el.path == window.location.pathname)
    );

    if (
      (config.url == "/api/v1/user/LoginExternal" ||
        config.url == "/api/v1/user/CheckSocialBind") &&
      response.status == 200
    )
      return;
    let responseBody =
      response.status == 200 || response.status < 500
        ? JSON.stringify(response.data)
        : "";
    let actionName = action ? action.action : "";
    let actionId = action ? action.id : 2;
    let payload = {
      LogLevelId: actionId,
      Menu: window.location.pathname.replace("/", " ").trim(),
      Action: actionName,
      DeviceOS: window.navigator.platform,
      DeviceType: window.navigator.userAgent,
      UserGUID: store.state.shortProfile.user_guid,
      Method: config.method,
      Url: config.url,
      ResponseCode: response.status,
      Body: config.data,
      ResponseBody: responseBody,
      RequestTime: config.metadata.startTime,
      ResponseTime: duration.toString()
    };
    const key = BrandKey.find(el => el.brand.toLowerCase() == brand);
    axios.post(`${process.env.VUE_APP_LOGGER_API}/Log`, payload, {
      headers: {
        "api-key": key ? key.key : "Cmd9Fk3QosXlakF0gMkeQ9fASogF"
      }
    });
  } catch (error) {
    console.error("logger error:", error);
  }
};
async function fetchConfig() {
  try {
    if (brand == "5t8z5pcj") {
      brand = "hourglass";
    }

    document.title = brand.charAt(0).toUpperCase() + brand.slice(1);
    if (mode == "dev") {
      icon.value = "/images/dev.ico";
      document.title = "D-CRM";
    } else icon.value = `/images/${brand}.ico`;
    const response = await instance.get("/api/v1/setting/getline");

    return response.detail; // Assuming the response is JSON data
  } catch (error) {
    window.alert(`errr: ${error}`);
    return null;
  }
}
export { instance, axios, fetchConfig };
